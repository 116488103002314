<template>
  <div class="apiPreview" v-if="dataList" ref="orderForm1">
    <div class="export" @click="exportPDF">
      <a-button type="primary">导出pdf</a-button>
    </div>
    <h2 class="interface-title">基本信息</h2>
    <a-descriptions :column="2" class="desc">
      <a-descriptions-item label="接口名称">{{
        dataList.title
      }}</a-descriptions-item>
      <a-descriptions-item label="创建人" v-if="!$route.query.uuid">
        <a-avatar
          size="small"
          :src="dataList.avatar"
          class="avatar"
          v-if="dataList.avatar"
        ></a-avatar>
        <span>
          {{ dataList.userLoginName }}
        </span>
      </a-descriptions-item>
      <a-descriptions-item label="状态" v-if="!$route.query.uuid">
        <a-tag v-if="dataList.status == 'done'" color="green"> 已完成 </a-tag>
        <a-tag v-else color="red"> 未完成 </a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="更新时间">{{
        dataList.gmtCreated
      }}</a-descriptions-item>
      <a-descriptions-item label="接口路径">
        <!-- <span @mouseenter="show = true" @mouseleave="show = false"> -->
        <a-tag v-if="dataList.method == 'GET'" color="green"> GET </a-tag>
        <a-tag v-else color="blue"> POST </a-tag>
        {{ dataList.path }}
        <a-tooltip v-if="show">
          <template slot="title"> 复制路径 </template>
          <a-icon type="copy" v-copy="dataList.path" style="cursor: pointer" />
        </a-tooltip>
        <!-- </span> -->
      </a-descriptions-item>
    </a-descriptions>
    <h2 class="interface-title" v-if="dataList.markdown">备注</h2>
    <div class="desc" v-if="dataList.markdown">
      {{ dataList.markdown }}
    </div>
    <h2 class="interface-title" v-if="dataList.content">附加信息</h2>
    <div class="desc" v-if="dataList.content">
      {{ dataList.content }}
    </div>
    <h2 class="interface-title" v-if="requestShow">请求参数</h2>
    <div class="desc" v-if="requestShow">
      <h3 v-if="dataList.reqQuery.length > 0" style="margin-top: 20px">
        Query:
      </h3>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(QueryColumns)"
        :columns="QueryColumns"
        :dataSource="dataList.reqQuery"
        size="small"
        :pagination="false"
        v-if="dataList.reqQuery.length > 0"
        :rowKey="(record) => record.name"
        bordered
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <span slot="required" slot-scope="text" style="width: 100%">
          {{ text == "0" ? "否" : "是" }}
        </span>
      </a-table>
      <h3 v-if="dataList.reqHeaders.length > 0" style="margin-top: 20px">
        Headers:
      </h3>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(HeadersColumns)"
        :columns="HeadersColumns"
        :dataSource="dataList.reqHeaders"
        size="small"
        :pagination="false"
        v-if="dataList.reqHeaders.length > 0"
        :rowKey="(record) => record.name"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        bordered
      >
        <span slot="required" slot-scope="text" style="width: 100%">
          {{ text == "0" ? "否" : "是" }}
        </span>
      </a-table>
      <h3 v-if="reqBodyShow" style="margin-top: 20px">Body:</h3>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(BodyFormColumns)"
        :columns="BodyFormColumns"
        :dataSource="dataList.reqBodyForm"
        size="small"
        :pagination="false"
        v-if="dataList.reqBodyForm.length > 0 && dataList.reqBodyType == 'form'"
        :rowKey="(record) => record.name"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        bordered
      >
        <span slot="type" slot-scope="text" style="width: 100%">
          {{ text == "text" ? "文本" : "文件" }}
        </span>
        <span slot="required" slot-scope="text" style="width: 100%">
          {{ text == "0" ? "否" : "是" }}
        </span>
      </a-table>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(BodyJsonColumns)"
        :columns="BodyJsonColumns"
        :dataSource="reqBodyOther"
        size="small"
        :pagination="false"
        v-if="reqBodyOther.length > 0 && dataList.reqBodyType == 'json'"
        :rowKey="(record) => record.name"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        bordered
      >
        <span slot="required" slot-scope="text" style="width: 100%">
          {{ text ? "是" : "否" }}
        </span>
        <span
          slot="name"
          slot-scope="text, record, index"
          style="width: 100%; text-align: left; display: inline-block"
        >
          <span
            style="float: left; cursor: pointer; color: #ababab"
            v-if="record.type == 'object' || record.type == 'array'"
          >
            <a-icon
              type="plus-square"
              v-if="!record.show"
              @click="expand(reqBodyOther, record, index)"
            />
            <a-icon
              type="minus-square"
              v-else
              @click="fold(reqBodyOther, record, index)"
            />
          </span>
          <span :style="objectStyle(record)"> {{ text }} </span>
        </span>
      </a-table>
      <div
        v-if="
          (dataList.reqBodyType == 'file' || dataList.reqBodyType == 'raw') &&
          reqBodyOther.length > 0
        "
      >
        <codemirror
          v-model="dataList.reqBodyOther"
          :options="temOptions"
          class="code_style"
        >
        </codemirror>
      </div>
    </div>
    <h2 class="interface-title" v-if="dataList.resBody">返回数据</h2>
    <div class="desc">
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(BodyJsonColumns)"
        :columns="BodyJsonColumns"
        :dataSource="resBody"
        size="small"
        :pagination="false"
        v-if="resBody.length > 0 && dataList.resBodyType == 'json'"
        :rowKey="(record) => record.name"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        bordered
      >
        <span slot="required" slot-scope="text" style="width: 100%">
          {{ text ? "是" : "否" }}
        </span>
        <span
          slot="name"
          slot-scope="text, record, index"
          style="width: 100%; text-align: left; display: inline-block"
        >
          <span
            style="float: left; cursor: pointer; color: #ababab"
            v-if="record.type == 'object' || record.type == 'array'"
          >
            <a-icon
              type="plus-square"
              v-if="!record.show"
              @click="expand(resBody, record, index)"
            />
            <a-icon
              type="minus-square"
              v-else
              @click="fold(resBody, record, index)"
            />
          </span>
          <span :style="objectStyle(record)"> {{ text }} </span>
        </span>
      </a-table>
      <div v-if="dataList.resBodyType == 'raw'">
        <codemirror
          v-model="dataList.resBody"
          :options="temOptions"
          class="code_style"
        >
        </codemirror>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "apiPreview",
  data() {
    return {
      QueryColumns: [
        {
          title: "参数名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
          align: "center",
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "required",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "required" },
        },
        {
          title: "示例",
          ellipsis: true,
          dataIndex: "example",
          width: 150,
          align: "center",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "desc",
        },
      ],
      BodyFormColumns: [
        {
          title: "参数名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
          align: "center",
        },
        {
          title: "参数类型",
          ellipsis: true,
          dataIndex: "type",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "required",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "required" },
        },
        {
          title: "示例",
          ellipsis: true,
          dataIndex: "example",
          width: 150,
          align: "center",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "desc",
        },
      ],
      BodyJsonColumns: [
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "type",
          width: 100,
          align: "center",
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "required",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "required" },
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "description",
        },
      ],
      HeadersColumns: [
        {
          title: "参数名称",
          ellipsis: true,
          dataIndex: "name",
          width: 300,
          align: "center",
        },
        {
          title: "参数值",
          ellipsis: true,
          dataIndex: "value",
          width: 300,
          align: "center",
        },
        {
          title: "是否必须",
          ellipsis: true,
          dataIndex: "required",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "required" },
        },
        {
          title: "示例",
          ellipsis: true,
          dataIndex: "example",
          width: 150,
          align: "center",
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "desc",
        },
      ],
      show: true,
      reqBodyOther: [],
      resBody: [],
      temOptions: {
        mode: { name: "javascript", json: true },
        lineNumbers: true,
        styleActiveLine: true, //设置光标所在行高亮，需引入工具包
        placeholder: "输入内容",
        readOnly: "nocursor",
      },
    };
  },
  props: ["dataList"],
  computed: {
    objectStyle() {
      return (record) => {
        if (record.type == "object" || record.type == "array") {
          return { "margin-left": record.grade * 20 + "px" };
        } else {
          return { "margin-left": record.grade * 20 + 14 + "px" };
        }
      };
    },
    reqBodyShow() {
      if (
        this.dataList.reqBodyForm.length > 0 &&
        this.dataList.reqBodyType == "form"
      ) {
        return true;
      } else if (
        this.reqBodyOther.length > 0 &&
        (this.dataList.reqBodyType == "json" ||
          this.dataList.reqBodyType == "file" ||
          this.dataList.reqBodyType == "raws")
      ) {
        return true;
      } else {
        return false;
      }
    },
    requestShow() {
      if (Array.isArray(this.dataList.reqHeaders)) {
        if (
          this.dataList.reqHeaders.length > 0 ||
          this.dataList.reqBodyForm.length > 0 ||
          this.reqBodyShow ||
          this.dataList.reqQuery.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler() {
        if (this.dataList.reqBodyType !== "form") {
          if (this.dataList.reqBodyOther) {
            let obj = JSON.parse(this.dataList.reqBodyOther);
            this.reqBodyOther = this.objArr(obj.properties, 1);
            this.expandAll(this.reqBodyOther);
          }
        }
        if (this.dataList.resBodyType == "json") {
          if (this.dataList.resBody) {
            let obj = JSON.parse(this.dataList.resBody);
            this.resBody = this.objArr(obj.properties, 1);
            this.expandAll(this.resBody);
          }
        }
      },
    },
  },
  methods: {
    async expandAll(arr, properties, propertiesIndex) {
      if (properties) {
        await properties.forEach((item, index) => {
          if (item.properties) {
            if (!item.show) {
              arr.splice(propertiesIndex + index + 1, 0, ...item.properties);
              this.$set(item, "show", true);
              this.expandAll(arr, item.properties, propertiesIndex + index + 1);
            }
          }
        });
      } else {
        await arr.forEach((item, index) => {
          if (item.properties) {
            if (!item.show) {
              arr.splice(index + 1, 0, ...item.properties);
              this.$set(item, "show", true);
              this.expandAll(arr, item.properties, index + 1);
            }
          }
        });
      }
    },
    // 下载pdf
    exportPDF() {
      location.href =
        process.env.VUE_APP_CURRENTMODE == "dev"
          ? process.env.VUE_APP_BASE_API
          : location.origin + `/api/yapi/exportPdf?id=${this.dataList.id}`;
    },
    objArr(val, grade) {
      let arr = [];
      for (const key in val) {
        if (val[key].properties) {
          arr.push({
            name: key,
            type: val[key].type,
            description: val[key].description,
            required: val[key].required,
            properties: this.objArr(val[key].properties, grade + 1),
            show: false,
            grade,
          });
        } else {
          arr.push({
            name: key,
            type: val[key].type,
            description: val[key].description,
            required: val[key].required,
            grade,
          });
        }
      }
      return arr;
    },
    expand(arr, record, index) {
      arr.splice(index + 1, 0, ...record.properties);
      this.$set(record, "show", true);
    },
    fold(arr, record, index) {
      record.properties.forEach((element, eleIndex) => {
        if (
          (element.type == "object" || element.type == "array") &&
          element.show
        ) {
          this.fold(arr, element, index + eleIndex);
        }
      });
      arr.splice(index + 1, record.properties.length);
      this.$set(record, "show", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.apiPreview {
  position: relative;
  .interface-title {
    clear: both;
    font-weight: 400;
    margin-top: 0.48rem;
    margin-bottom: 1rem;
    border-left: 3px solid #2395f1;
    padding-left: 8px;
  }
  .desc {
    margin-left: 20px;
    margin-top: 20px;
  }
  .export {
    position: absolute;
    top: 0px;
    right: 20px;
  }
}
.code_style {
  height: 100%;
  &::v-deep .CodeMirror {
    height: 100% !important;
  }
}
.table-striped td {
  background-color: #fafafa;
}
.avatar {
  vertical-align: middle !important;
  margin: 0 10px !important;
  margin-bottom: 5px !important;
}
</style>
