<template>
  <div class="vpnManager">
    <div class="searchCondition">
      <div class="searchConditionItem">
        vpn名称：
        <a-input
          placeholder="请输入vpn名称"
          v-model.trim="name"
          @pressEnter="query(1)"
          @change="allowClearChange"
          allowClear
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addClick" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="userListClick(record)">用户列表</a>
      </span>
    </a-table>
    <!-- 新增vpn -->
    <a-modal
      :title="modifyTitle"
      v-model="modifyShow"
      :maskClosable="false"
      :afterClose="modifyClose"
      width="580px"
    >
      <a-form>
        <a-form-item
          label="vpn名称:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入vpn名称"
            v-model="modifyForm.name"
            allowClear
          />
        </a-form-item>
        <a-form-item
          label="服务器:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            v-model="serverId"
            placeholder="请选择服务器"
            showSearch
            :filter-option="false"
            @search="serverIdSearch"
            allowClear
          >
            <a-select-option v-for="item in instanceList" :key="item.id">{{
              item.instanceName
            }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modify_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 用户列表 -->
    <a-modal
      :title="vpnUserTitle"
      v-model="vpnUserShow"
      :maskClosable="false"
      :afterClose="vpnUserClose"
      width="1000px"
    >
      <div class="searchCondition">
        <div class="searchButton">
          <a-button type="primary" @click="addUserClick" icon="plus"
            >新增</a-button
          >
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(tableUserColumns)"
        :columns="tableUserColumns"
        :dataSource="tableUserDateSource"
        :pagination="tableUserPagination"
        :loading="loadingTable"
        size="small"
      >
        <span slot="enabled" slot-scope="text">
          <a-tag color="green" v-if="text == true">已启用</a-tag>
          <a-tag color="red" v-if="text == false">禁用</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="downloadCertificate(record)"
            >下载证书</a
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="right"
            okText="确认"
            cancelText="取消"
            @confirm="deleteClick(record)"
          >
            <template slot="title">确认是否删除{{ record.userName }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="vpnUser_submit"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 新增vpn用户 -->
    <a-modal
      :title="modifyUserTitle"
      v-model="modifyuserShow"
      :maskClosable="false"
      :afterClose="modifyuserClose"
      width="580px"
    >
      <a-form>
        <a-form-item
          label="用户名:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-select
            :value="modifyUserForm.userName"
            style="width: 100%"
            :filterOption="false"
            @search="searchUserName"
            @change="handleChange"
            placeholder="请输入关键词"
            allowClear
            showSearch
          >
            <a-select-option v-for="i in searchList" :key="i.loginName">{{
              i.loginName
            }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="账号:"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 16 }"
          required
        >
          <a-input
            placeholder="请输入账号"
            v-model="modifyUserForm.vpnUser"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="modifyuserShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="modifyUser_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/vpnManager.js";
import { userList } from "../lib/projectList.js";
import { serverList } from "../lib/serverList";
export default {
  name: "vpnManager",
  data() {
    return {
      port: "",
      userIp: "",
      vpnUserId: "",
      vpnId: "",
      updateStatus: false,
      name: "",
      ip: "",
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "vpn名称",
          ellipsis: true,
          dataIndex: "name",
        },
        {
          title: "服务器名称",
          ellipsis: true,
          dataIndex: "serverName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableUserColumns: [
        {
          title: "编号",
          align: "center",
          width: 60,
          ellipsis: true,
          dataIndex: "id",
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "vpnUser",
        },
        {
          title: "用户",
          ellipsis: true,
          dataIndex: "userName",
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tableUserDateSource: [],
      tablePagination: {},
      tableUserPagination: {},
      loadingTable: false,
      modifyForm: {
        name: "",
        ip: "",
        ipsecPsk: "",
        port: "",
      },
      modifyUserForm: {
        userName: undefined,
        vpnUser: "",
      },
      modifyTitle: "",
      vpnUserTitle: "",
      modifyUserTitle: "",
      modifyShow: false,
      vpnUserShow: false,
      modifyuserShow: false,
      loading: false,
      searchList: [],
      serverId: undefined,
      instanceList: [],
    };
  },
  mounted() {
    this.query(1);
    this.serverIdSearch();
  },
  methods: {
    downloadCertificate(val) {
      location.href =
        process.env.VUE_APP_CURRENTMODE == "dev"
          ? process.env.VUE_APP_BASE_API
          : location.origin +
            `/api/delivery/vpnManager/downloadCertificate?vpnUserId=${val.id}`;
    },
    serverIdSearch(val) {
      let data = {
        pageNo: 1,
        pageSize: 50,
        instanceName: val,
      };
      serverList(data).then((res) => {
        this.instanceList = res.data.records;
      });
    },
    // 复制密码
    copy(val) {
      // console.log(val);
      let data = {
        vpnUserId: val.id,
        vpnId: this.vpnId,
      };
      let parameter = {
        ip: this.userIp,
        port: this.port,
        user: val.userLoginName,
        password: "",
        ipsecPsk: "",
      };
      let dbName = val.vpnName;
      let message = ""; // copy内容
      api
        .getPassword(data)
        .then((res) => {
          if (res.result === 200) {
            // parameter.port = res.data.port;
            parameter.password = res.data.vpnPassword;
            parameter.ipsecPsk = res.data.ipsecPsk;
            message = this.$common.copyInfo(parameter, dbName); // 复制数据库信息
            this.$copyText(message).then(
              (e) => {
                this.$message.success("复制成功");
              },
              (e) => {
                this.$message.error("复制失败");
              }
            );
          }
        })
        .catch((err) => {});
    },
    // 搜索管理员
    searchUserName(val) {
      userList({ name: val }).then((res) => {
        if (res.result == 200) {
          this.searchList = res.data.userList;
        }
      });
    },
    // 选择分享xxx
    handleChange(val) {
      this.modifyUserForm.userName = val;
      this.modifyUserForm.vpnUser = val;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        name: this.name,
      };
      this.getVpnList(data);
    },
    // 查询账号列表
    getVpnList(data) {
      this.loadingTable = true;
      api
        .vpnList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 点击新增
    addClick() {
      this.modifyTitle = "新增";
      this.modifyShow = true;
    },
    // 点击用户新增
    addUserClick() {
      this.modifyUserTitle = "新增";
      this.modifyuserShow = true;
    },
    // 确定新增
    modify_submit() {
      let data = {
        name: this.modifyForm.name,
        serverId: this.serverId,
      };
      if (!data.name) {
        this.$message.warning("请输入vpn名称");
        return;
      }
      if (!data.serverId) {
        this.$message.warning("请选择服务器");
        return;
      }
      this.loading = true;
      api
        .vpnAdd(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.modifyShow = false;
            this.query(1);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 确定用户新增
    modifyUser_submit() {
      let data = {
        userName: this.modifyUserForm.userName,
        vpnUser: this.modifyUserForm.vpnUser,
        vpnId: this.vpnId,
      };
      if (!data.userName) {
        this.$message.warning("请输入用户名");
        return;
      }
      if (!data.vpnUser) {
        this.$message.warning("请输入账号");
        return;
      }
      this.loading = true;
      api
        .vpnUserAdd(data)
        .then((res) => {
          this.loading = false;
          if (res.result === 200) {
            this.$message.success("新增成功");
            this.modifyuserShow = false;
            this.getVpnUserList();
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 关闭新增
    modifyClose() {
      this.modifyForm = {
        name: "",
        ip: "",
        ipsecPsk: "",
        port: "",
      };
    },
    // 关闭用户新增
    modifyuserClose() {
      this.modifyUserForm = {
        userName: "",
        vpnUser: "",
      };
    },
    // 点击用户列表
    userListClick(val) {
      // console.log(val)
      this.userIp = val.ip;
      this.vpnId = val.id;
      this.vpnUserTitle = val.name + "-用户列表";
      this.vpnUserShow = true;
      this.getVpnUserList();
      this.port = val.port;
    },
    getVpnUserList() {
      this.loadingTable = true;
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        vpnId: this.vpnId,
      };
      api
        .vpnUserList(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableUserDateSource = list;
            this.tableUserPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    vpnUserClose() {},
    vpnUser_submit() {
      this.vpnUserShow = false;
      this.query();
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        vpnUserId: val.id,
        vpnId: this.vpnId,
      };
      api.deleteVpnUser(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.getVpnUserList();
        }
      });
    },
    examine(val) {
      this.vpnUserId = val.id;
    },
    confirm() {
      let data = {
        vpnUserId: this.vpnUserId,
        vpnId: this.vpnId,
        enabled: true,
      };
      api.editVpnUser(data).then((res) => {
        if (res.result === 200) {
          this.addRedVisible = false;
          this.$message.success("启用成功");
          this.getVpnUserList();
        }
      });
    },
    examineFalse(val) {
      this.vpnUserId = val.id;
    },
    confirmFalse() {
      let data = {
        vpnUserId: this.vpnUserId,
        vpnId: this.vpnId,
        enabled: false,
      };
      api.editVpnUser(data).then((res) => {
        if (res.result === 200) {
          this.addRedVisible = false;
          this.$message.success("禁用成功");
          this.getVpnUserList();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
